import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import BlogList from '../components/BlogList';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';
import { Helmet } from 'react-helmet'
import SvgCharts from '../svg/SvgCharts';
import { graphql, StaticQuery } from 'gatsby';

// Icons
import { FaWind, FaTv, FaWifi, FaBath, FaUtensils, FaUserAlt } from 'react-icons/fa';

function htmlDecode(input){
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

function Page ({pageContext}) {
    
    return(
        <Layout>
            <section id="testimonials" className="py-5 lg:py-10">
            <Helmet title="Kost Eksklusif Mara Juara Depok" defer={false} />
            <div className="container mx-5 w-11/12 md:mx-auto text-center">
                <h2 dangerouslySetInnerHTML={{__html:pageContext.title}} className="mb-3 text-3xl lg:text-5xl font-semibold" />
                {/* <LabelText className="mb-8 text-gray-600 text-center">Dapatkan informasi terkini seputar kehidupan kost</LabelText> */}
                <div className="flex flex-col md:flex-row md:-mx-3 lg:mx-20 text-justify"> 
                    <div className="text-justify" dangerouslySetInnerHTML={{__html: pageContext.content}} />
                </div>
            </div>
            </section>
        </Layout>
    );
}

export default Page;